import { FC } from 'react';

import { getEnv } from '@lib/env/getEnv';

import { Tenant } from '../types';

interface Props {
  tenant: Tenant;
}

export const HeadTags: FC<Props> = (props) => {
  const { tenant } = props;
  const { name, cmsSubdomain } = tenant.config.setup;
  const env = getEnv();

  return (
    <>
      <meta name="theme-color" content="#FFFFFF" />
      <link rel="manifest" href={`/tenants/${name}/manifest.json`} />
      <link
        rel="shortcut icon"
        href={`/tenants/${name}/icons/favicon/favicon.ico`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="57x57"
        href={`/tenants/${name}/icons/favicon/apple-touch-icon-57x57.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="114x114"
        href={`/tenants/${name}/icons/favicon/apple-touch-icon-114x114.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="72x72"
        href={`/tenants/${name}/icons/favicon/apple-touch-icon-72x72.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href={`/tenants/${name}/icons/favicon/apple-touch-icon-144x144.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="60x60"
        href={`/tenants/${name}/icons/favicon/apple-touch-icon-60x60.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="120x120"
        href={`/tenants/${name}/icons/favicon/apple-touch-icon-120x120.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="76x76"
        href={`/tenants/${name}/icons/favicon/apple-touch-icon-76x76.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href={`/tenants/${name}/icons/favicon/apple-touch-icon-152x152.png`}
      />
      <link
        rel="icon"
        type="image/png"
        href={`/tenants/${name}/icons/favicon/favicon-196x196.png`}
        sizes="196x196"
      />
      <link
        rel="icon"
        type="image/png"
        href={`/tenants/${name}/icons/favicon/favicon-96x96.png`}
        sizes="96x96"
      />
      <link
        rel="icon"
        type="image/png"
        href={`/tenants/${name}/icons/favicon/favicon-32x32.png`}
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href={`/tenants/${name}/icons/favicon/favicon-16x16.png`}
        sizes="16x16"
      />
      <link
        rel="icon"
        type="image/png"
        href={`/tenants/${name}/icons/favicon/favicon-128.png`}
        sizes="128x128"
      />
      <meta name="application-name" content="&nbsp;" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta
        name="msapplication-TileImage"
        content={`/tenants/${name}/icons/favicon/mstile-144x144.png`}
      />
      <meta
        name="msapplication-square70x70logo"
        content={`/tenants/${name}/icons/favicon/mstile-70x70.png`}
      />
      <meta
        name="msapplication-square150x150logo"
        content={`/tenants/${name}/icons/favicon/mstile-150x150.png`}
      />
      <meta
        name="msapplication-wide310x150logo"
        content={`/tenants/${name}/icons/favicon/mstile-310x150.png`}
      />
      <meta
        name="msapplication-square310x310logo"
        content={`/tenants/${name}/icons/favicon/mstile-310x310.png`}
      />
      <script
        async
        defer
        src={`https://static.cdn.prismic.io/prismic.js?new=true&repo=${
          cmsSubdomain[env.client.appEnv]
        }`}
      />
    </>
  );
};
