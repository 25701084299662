import { FC, ReactNode } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { SCROLL_CONTAINER_ID } from '@lib/scroll/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box } from '@mui/material';

import { useA2HS } from './a2hs/useA2HS';
import { DrawerProps } from './drawer/Drawer';
import { Footer } from './footer/Footer';
import { Header } from './header/Header';
import { Body } from './Body';
import { CookieConsent } from './CookieConsent';
import { ExitPreviewButtonProps } from './ExitPreviewButton';
import { HeadAppends } from './HeadAppends';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { HeadTags } from '@lib/tenants/config/HeadTags';
import Head from 'next/head';
import { BlueBoxChatBot } from './blueBoxAIchatBot/BlueBoxChatBot';
import { getEnv } from '@lib/env/getEnv';

const Drawer = dynamic<DrawerProps>(() =>
  import('./drawer/Drawer').then((val) => val.Drawer),
);
const ExitPreviewButton = dynamic<ExitPreviewButtonProps>(() =>
  import('./ExitPreviewButton').then((val) => val.ExitPreviewButton),
);

interface Props {
  children: ReactNode;
  tabTitle: string;
  metaDescription?: string;
  showContentLoader?: boolean;
  alert?: ReactNode;
}

export const PageLayout: FC<Props> = ({
  children,
  showContentLoader,
  tabTitle,
  metaDescription,
  alert,
}) => {
  const { isPreview } = useRouter();
  const { tenant } = useTenantProps();
  const env = getEnv();

  const blueBoxChatBotUrl =
    tenant.config.setup?.blueBoxChatBotUrl?.[env.client.appEnv];

  useA2HS();

  return (
    <Box sx={styles.getValue('root')}>
      <Head>
        <HeadTags tenant={tenant} />
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
      </Head>
      <Header />
      <Drawer />
      <HeadAppends tabTitle={tabTitle} />
      {alert}
      <Box
        sx={styles.getValue('content')}
        id={SCROLL_CONTAINER_ID}
        data-testid="main"
      >
        <Body showContentLoader={showContentLoader}>{children}</Body>
        <Footer />
      </Box>
      <CookieConsent />
      {isPreview && <ExitPreviewButton />}
      {!!blueBoxChatBotUrl && <BlueBoxChatBot />}
    </Box>
  );
};

const styles = new SxStyles({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: '#FAFAFA',
  },
  content: {
    display: 'flex',
    overflow: 'auto',
    height: '100%',
    flexDirection: 'column',
    position: 'relative',
  },
});
