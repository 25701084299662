import { FC } from 'react';
import Head from 'next/head';

interface Props {
  tabTitle: string;
}

export const HeadAppends: FC<Props> = (props) => {
  const { tabTitle } = props;

  return <Head>{tabTitle && <title>{tabTitle}</title>}</Head>;
};
