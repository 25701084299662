import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import Cookies from 'js-cookie';

import { APP_ROUTES } from '@lib/route/constants';
import { COOKIE_STORAGE } from '@lib/storage/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Slide, Typography } from '@mui/material';
import { ContentSpacer } from '@templates/common/layout/ContentSpacer';
import { Button } from '@ui/buttons/Button';
import { TextLink } from '@ui/buttons/TextLink';

export const CookieConsent: FC = () => {
  const { t } = useTranslation();
  const [hasConsent, setHasConsent] = useState<boolean>(true);

  useEffect(() => {
    const hasConsent = !!Cookies.get(COOKIE_STORAGE.COOKIE_CONSENT);
    setHasConsent(hasConsent);
  }, []);

  return (
    <Slide direction="up" in={!hasConsent} mountOnEnter unmountOnExit>
      <Box sx={styles.getValue('root')}>
        <ContentSpacer
          isPadded={false}
          contentProps={{ sx: styles.getValue('contentContainer') }}
        >
          <Typography sx={styles.getValue('text')}>
            <Trans t={t} i18nKey="common:notifications:cookieConsent">
              text
              <TextLink
                href={APP_ROUTES.PRIVACY_POLICY.path}
                display="inline-flex"
              />
            </Trans>
          </Typography>
          <Button sx={styles.getValue('btn')} onClick={handleClose}>
            {t('common:accept')}
          </Button>
        </ContentSpacer>
      </Box>
    </Slide>
  );

  function handleClose(): void {
    Cookies.set(COOKIE_STORAGE.COOKIE_CONSENT, '1', { expires: 365 });
    setHasConsent(true);
  }
};

const styles = new SxStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    py: 2,
    width: '100%',
    opacity: 0.8,
    bgcolor: (theme) => theme.palette.common.black,
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
  },
  text: {
    color: (theme) => theme.palette.common.white,
    m: (theme) => ({
      xs: theme.spacing(0, 0, 1, 0),
      sm: theme.spacing(0, 2, 0, 0),
    }),
  },
  btn: {
    minWidth: 90,
    flexShrink: 0,
  },
});
