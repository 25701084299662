import { FC } from 'react';

import { SxTheme } from '@lib/theme/types';
import {
  CircularProgress,
  CircularProgressProps,
  Grid,
  Typography,
} from '@mui/material';

interface Props extends CircularProgressProps {
  title?: string;
  containerSx?: SxTheme;
}

export const CenteredLoader: FC<Props> = ({
  title,
  containerSx,
  ...restLoaderProps
}) => {
  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={containerSx}
    >
      <CircularProgress {...restLoaderProps} />
      {title && (
        <Typography variant="h6" mt={1}>
          {title}
        </Typography>
      )}
    </Grid>
  );
};
