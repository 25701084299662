import { forwardRef, PropsWithChildren } from 'react';
import { useTranslation } from 'next-i18next';

import { SxTheme } from '@lib/theme/types';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { Box, CircularProgress } from '@mui/material';

type DefaultType = 'submit' | 'update' | 'create' | 'edit';

export interface ButtonProps extends Omit<LoadingButtonProps, 'onClick'> {
  defaultType?: DefaultType;
  contentSx?: SxTheme;
  onClick?: () => void;
}

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(
  (
    {
      defaultType = 'submit',
      children,
      variant = 'contained',
      sx,
      contentSx,
      ...restProps
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const defaultText: Record<DefaultType, string> = {
      submit: t('common:submit'),
      update: t('common:update'),
      create: t('common:create'),
      edit: t('common:edit'),
    };

    return (
      <LoadingButton
        ref={ref}
        color="primary"
        sx={{ borderRadius: '20px', ...sx }}
        variant={variant}
        loadingIndicator={
          <CircularProgress
            size={16}
            thickness={5}
            color={variant === 'contained' ? 'light' : 'primary'}
          />
        }
        {...restProps}
      >
        <Box
          component="span"
          display="block"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          sx={contentSx}
        >
          {children || defaultText[defaultType]}
        </Box>
      </LoadingButton>
    );
  },
);

Button.displayName = 'Button';
