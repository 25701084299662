import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Stack, Typography } from '@mui/material';

export const A2HSText: FC = () => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      <AddOutlinedIcon />
      <Typography>{t('common:a2hs')}</Typography>
    </Stack>
  );
};
