import { scroller } from 'react-scroll';
import { LinkProps } from 'next/link';

import { SCROLL_CONTAINER_ID } from './constants';

export const scrollTo = (fragmentId: string): void => {
  scroller.scrollTo(fragmentId, {
    smooth: true,
    containerId: SCROLL_CONTAINER_ID,
  });
};

export const hashToFragment = (hash: string): string => {
  if (hash.startsWith('#')) {
    const fragmentId = hash.slice(1, hash.length);

    return fragmentId;
  }

  return hash;
};

export const scrollOnActive = (
  href: LinkProps['href'],
  location: Location
): void => {
  const { hash, origin, pathname } = location;
  const isActivePath =
    typeof href === 'string'
      ? href.startsWith(origin + pathname)
      : href.pathname === pathname;
  const fragmentId = hashToFragment(hash);

  if (isActivePath && !!hash) {
    scrollTo(fragmentId);
  }
};
