import { FC } from 'react';
import NextImage, { ImageProps, StaticImageData } from 'next/image';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, BoxProps } from '@mui/material';

export interface DynamicImageProps extends ImageProps {
  src: string | StaticImageData;
  alt: string;
  height?: number | undefined;
  width?: number | undefined;
  containerProps?: BoxProps;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
}

export const DynamicImage: FC<DynamicImageProps> = ({
  src,
  width,
  height,
  priority = true,
  alt = '',
  containerProps = {},
  fill,
  objectFit = 'cover',
  ...rest
}) => {
  const { sx, ...restContainerProps } = containerProps;

  return (
    <Box
      sx={{ ...styles.getValue('imageWrapper'), ...sx }}
      {...restContainerProps}
    >
      {fill ? (
        <NextImage
          src={src}
          alt={alt ?? ''}
          priority={priority}
          quality={100}
          fill
          style={{ objectFit }}
        />
      ) : (
        <NextImage
          src={src}
          priority={priority}
          width={width}
          height={height}
          quality={100}
          alt={alt ?? ''}
          style={{ objectFit, maxWidth: '100%' }}
          {...rest}
        />
      )}
    </Box>
  );
};

const styles = new SxStyles({
  imageWrapper: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
});
