import { Breakpoint } from '@mui/system';
import { BlockWidth, ModalWidth, TextColor } from './types';

export const TEXT_COLOR_LABELS: TextColor[] = [
  'text.primary',
  'light.main',
  'dark.main',
  'primary.light',
  'primary.main',
  'primary.dark',
  'secondary.light',
  'secondary.main',
  'secondary.dark',
];

export const BLOCK_WIDTH_OPTIONS: Record<BlockWidth, Breakpoint> = {
  default: 'xl',
  small: 'sm',
  medium: 'md',
  large: 'lg',
};

export const MODAL_WIDTH_OPTIONS: Record<ModalWidth, Breakpoint> = {
  default: 'md',
  small: 'sm',
  large: 'lg',
};
