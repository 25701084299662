import { SxTheme } from './types';

export class SxStyles<Key extends string, Value extends SxTheme> {
  constructor(public styles: Record<Key, Value>) {}

  getValue(key: Key): Value {
    return this.styles[key];
  }

  merge<IsSystemTheme extends boolean = true>(
    ...styles: (SxTheme<IsSystemTheme> | Key | undefined | false)[]
  ): SxTheme<IsSystemTheme> {
    let accumulator = {};

    styles.forEach((style) => {
      if (typeof style === 'string') {
        accumulator = {
          ...accumulator,
          ...this.styles[style],
        };
      } else if (style) {
        accumulator = {
          ...accumulator,
          ...style,
        };
      }
    });

    return accumulator;
  }
}
