import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { formatGenericError } from '@lib/fetch/errors';

import {
  A2HSResponse,
  IBeforeInstallPromptEvent,
  PromptToInstall,
} from './types';

export interface A2HSProps {
  onAccepted?: () => void;
  onDismissed?: () => void;
}

export const useA2HSPrompt = (config?: A2HSProps): A2HSResponse => {
  const [promptEvent, setPromptEvent] =
    useState<IBeforeInstallPromptEvent | null>(null);

  const promptToInstall: PromptToInstall = useCallback(() => {
    if (promptEvent) {
      promptEvent.prompt();
    }
  }, [promptEvent]);

  useEffect(() => {
    const listener = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      setPromptEvent(e);

      e.userChoice
        .then((result) => {
          if (result.outcome === 'accepted') {
            if (config?.onAccepted) config.onAccepted();
          } else {
            if (config?.onDismissed) config.onDismissed();
          }

          return;
        })
        .catch((err) => toast.error(formatGenericError(err)));
    };

    window.addEventListener(
      'beforeinstallprompt',
      listener as EventListenerOrEventListenerObject
    );

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        listener as EventListenerOrEventListenerObject
      );
    };
  }, [config]);

  return [promptEvent, promptToInstall];
};
