import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'next-i18next';

import { Card, CardContent, Grid, Typography } from '@mui/material';
import { ContentSpacer } from '@templates/common/layout/ContentSpacer';

type Props = FallbackProps;

export const ContentFallback: FC<Props> = (props) => {
  const { error } = props;
  const { t } = useTranslation();

  return (
    <ContentSpacer>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="error" variant="h5" component="h1">
                {t('common:errors.unexpected')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="error" variant="body2" component="p">
                {error.message}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ContentSpacer>
  );
};
