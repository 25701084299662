import { FC } from 'react';

import { FooterMenuDoc } from '@api/prismic/components/types';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Grid, Typography } from '@mui/material';

interface Props {
  footnote?: FooterMenuDoc['data']['footnote'];
}

export const BottomBar: FC<Props> = ({ footnote }) => {
  return (
    <Box sx={styles.getValue('container')}>
      <Grid
        container
        alignItems="center"
        flexDirection={{ xs: 'column', lg: 'row' }}
        spacing={{ xs: 1, lg: 0 }}
      >
        <Grid item xs={12}>
          <Typography variant="body2" component="span">
            {footnote}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = new SxStyles({
  container: {
    mt: 4,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: (theme) => theme.spacing(2, 0),
  },
});
