import { SYMBOLS } from '@lib/placeholders/constants';
import { APP_ROUTES, DEFAULT_LOCALE } from '@lib/route/constants';
import { replaceRouteParams } from '@lib/route/routeParams';

import { LinkTarget, LinkToWebType, ParsedLink } from './types';
import {
  FilledLinkToWebField,
  LinkType,
  RichTextField,
  RichTextNodeType,
  RTLinkNode,
  RTTextNodeBase,
} from '@prismicio/types';
import { hasRichTextContent } from './richText';

// Manages the url links to internal Prismic documents
export const linkResolver = (doc: LinkToWebType): string => {
  const lang = doc.lang ?? DEFAULT_LOCALE;
  const langPrefix = DEFAULT_LOCALE === lang ? '' : `/${lang}`;

  if (doc.type === 'page') {
    return replaceRouteParams(langPrefix + APP_ROUTES.ROOT_UID.path, {
      uid: doc.uid,
    });
  } else if (doc.type === 'blog') {
    return replaceRouteParams(langPrefix + APP_ROUTES.BLOG.path, {
      uid: doc.uid,
    });
  } else if (doc.type === 'home-page') {
    return langPrefix + APP_ROUTES.HOME.path;
  } else if (doc.type === 'available_products') {
    return langPrefix + APP_ROUTES.PLANS.path;
  } else if (doc.type === 'privacy-policy') {
    return langPrefix + APP_ROUTES.PRIVACY_POLICY.path;
  } else if (doc.type === 'terms-and-conditions') {
    return langPrefix + APP_ROUTES.TERMS_AND_CONDITIONS.path;
  } else if (
    (doc.link_type === 'Web' || doc.link_type === 'Media') &&
    doc?.url
  ) {
    return doc.url;
  }

  return langPrefix + APP_ROUTES.HOME.path;
};

export function isLinkDefined(doc: LinkToWebType): boolean {
  return doc.link_type !== LinkType.Any;
}

export function parseLink(richTextLink?: RichTextField[]): ParsedLink {
  let title: string = '';
  let href: string | undefined = undefined;
  let target: LinkTarget | undefined = undefined;

  if (richTextLink && hasRichTextContent(richTextLink)) {
    const richLink = richTextLink?.[0] as unknown as RTTextNodeBase;
    title = richLink?.text ?? SYMBOLS.emptyText;
    const link = (
      richLink?.spans?.find(
        (span) => span.type === RichTextNodeType.hyperlink,
      ) as RTLinkNode
    )?.data as FilledLinkToWebField;

    if (link) {
      href = linkResolver(link);
      target = link?.target as LinkTarget;
    }
  }

  return { title, href, target };
}
