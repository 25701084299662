import React, { FC } from 'react';

import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { SHARED_PALETTE } from '@lib/theme/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Container, Grid, Typography } from '@mui/material';
import { DynamicImage } from '@ui/gallery/DynamicImage';

import { BottomBar } from './BottomBar';
import { LinkList } from './LinkList';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import { FooterTopBarItemSize } from '@api/prismic/components/types';

export const Footer: FC = () => {
  const { footerMenu } = useTenantProps();

  if (!footerMenu) {
    return null;
  }

  return (
    <>
      <Grid container sx={{ display: { xs: 'none', md: 'flex' } }}>
        {footerMenu?.footerTopBar
          ?.filter((item) => item?.size)
          ?.map((item, index) => (
            <Grid
              item
              xs={12}
              md={getGridSizeFromFooterTopBar(item.size)}
              key={`footer-top-bar-item-${index}`}
              bgcolor={item.backgroundColor}
              sx={{
                ...styles.getValue('footerTopBarItem'),
                justifyContent: item?.alignment ?? 'left',
              }}
            >
              <CustomRichText render={item?.text} />
            </Grid>
          ))}
      </Grid>
      <Box sx={styles.getValue('root')}>
        <Container maxWidth="xl" sx={styles.getValue('container')}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              container
              direction="column"
              alignItems="center"
            >
              {footerMenu?.logoTitle && (
                <Typography variant="body2" sx={styles.getValue('title')}>
                  {footerMenu?.logoTitle}
                </Typography>
              )}
              {footerMenu?.logo?.url && (
                <Box sx={styles.getValue('imageWrapper')}>
                  <DynamicImage
                    containerProps={{ sx: { maxWidth: 200 } }}
                    src={footerMenu?.logo.url}
                    alt={footerMenu.logo.alt}
                    width={footerMenu.logo.dimensions.width ?? 200}
                    height={150}
                    objectFit="contain"
                    priority={false}
                  />
                </Box>
              )}
            </Grid>
            <LinkList />
          </Grid>
          <BottomBar footnote={footerMenu?.footnote} />
        </Container>
      </Box>
    </>
  );
};

function getGridSizeFromFooterTopBar(size: FooterTopBarItemSize) {
  const sizes = {
    small: 4,
    medium: 8,
    large: 12,
  };

  return sizes[size];
}

const styles = new SxStyles({
  root: {
    display: 'flex',
    py: 6,
    bgcolor: SHARED_PALETTE.lightGrey,
  },
  footerTopBarItem: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    p: 2,
  },
  container: {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  title: {
    fontWeight: 'fontWeightMedium',
    fontSize: 14,
    color: 'text.primary',
    textAlign: 'center',
  },
  imageWrapper: {
    pb: 0.5,
  },
});
