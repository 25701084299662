import { FC, ReactElement } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import { SxStyles } from '@lib/theme/sxTheme';
import {
  Link as MaterialLink,
  LinkProps as MaterialLinkProps,
} from '@mui/material';

export interface NodeLinkProps extends Omit<MaterialLinkProps, 'href'> {
  href: NextLinkProps['href'] | undefined;
  children: ReactElement;
}

export const NodeLink: FC<NodeLinkProps> = (props) => {
  const {
    href,
    children,
    color = 'primary',
    underline = 'hover',
    rel = 'nofollow noopener noreferrer',
    ...rest
  } = props;

  if (!href) {
    return children;
  }

  return (
    <NextLink href={href} passHref legacyBehavior>
      <MaterialLink
        sx={styles.getValue('root')}
        color={color}
        underline={underline}
        rel={rel}
        {...rest}
      >
        {children}
      </MaterialLink>
    </NextLink>
  );
};

const styles = new SxStyles({
  root: {
    cursor: 'pointer',
  },
});
