import { grey } from '@mui/material/colors';

export const SHARED_PALETTE = {
  darkestGrey: grey[800],
  darkerGrey: grey[700],
  darkGrey: grey[300],
  grey: grey[200],
  lightGrey: grey[100],
  lightestGrey: grey[50],
};
