import { FC } from 'react';
import { useRouter } from 'next/router';

import { isRouteActive } from '@lib/route/checkRoute';
import { SxStyles } from '@lib/theme/sxTheme';
import { SxTheme } from '@lib/theme/types';
import { Box } from '@mui/system';
import { TextLink } from '@ui/buttons/TextLink';

interface Props {
  href?: string;
  title?: string;
  target?: string;
  activeColor?: string;
  sx?: SxTheme;
}

export const ListItem: FC<Props> = ({
  href,
  title,
  target,
  activeColor,
  sx = {},
}) => {
  const { pathname } = useRouter();
  const isActive = isRouteActive(pathname, href);
  const dynamicStyles = styles({ activeColor, textSx: sx });

  if (!href) {
    return <Box sx={dynamicStyles.getValue('text')}>{title}</Box>;
  }

  return (
    <TextLink
      href={href}
      typographyProps={{
        sx: dynamicStyles.merge('text', isActive && 'active'),
      }}
      underline="none"
      color="inherit"
      target={target}
    >
      {title}
    </TextLink>
  );
};

interface StyleProps {
  activeColor: string | undefined;
  textSx: SxTheme;
}

const styles = ({ activeColor, textSx }: StyleProps) =>
  new SxStyles({
    text: {
      fontWeight: 'fontWeightRegular',
      fontSize: 14,
      ...textSx,
    },
    active: {
      color: activeColor ?? 'primary.main',

      '& .MuiSvgIcon-root': {
        color: activeColor ?? 'primary.main',
      },
    },
  });
