import { FC, ReactElement } from 'react';

import { useAuth } from '@lib/auth/AuthContext';
import { isProtectedLevel } from '@templates/common/layout/page-layout/common/helpers';
import {
  DefaultNavItemCategoryItem,
  DefaultNavItemCategoryPrimary,
} from '@ui/slice-machine/slices/nav-item-category-slice/types';

interface Props {
  navItem: DefaultNavItemCategoryPrimary | DefaultNavItemCategoryItem;
  children: ReactElement;
}

export const ProtectedNavItem: FC<Props> = (props: Props) => {
  const { navItem, children } = props;
  const { isAuthenticated } = useAuth();

  const isAuthOnly = navItem.visibility === 'auth_only';
  const isGuestOnly = navItem.visibility === 'guest_only';

  if (isProtectedLevel(isAuthenticated, isAuthOnly, isGuestOnly)) {
    return null;
  }

  return children;
};
