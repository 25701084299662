import {
  NavigationMenuDoc,
  NavigationMenuItemData,
} from '@api/prismic/components/types';
import { parseLink } from '@lib/slice-machine/linkResolver';
import {
  HeaderMenuItemVm,
  HeaderMenuVm,
  SliceType,
} from '@lib/slice-machine/types';

import { MenuItemStructure, MenuLevel } from '../types';

const mapHeaderData = (
  headerData: NavigationMenuDoc['data'] | null,
  sliceType: SliceType
): HeaderMenuVm | null => {
  if (!headerData) {
    return null;
  }

  const defaultVariationSlice = headerData.slices?.find(
    (menu) =>
      menu.variation === 'default-slice' && menu.slice_type === sliceType
  );

  const items = defaultVariationSlice?.items
    .map((x) => x.navigationMenuItem)
    .filter((x): x is HeaderMenuItemVm => !!x.id && !!x.data);

  return { default: items ?? [] };
};

export const mapToMenuItems = (
  headerData: NavigationMenuDoc['data'] | null,
  sliceType: SliceType
): MenuItemStructure[] => {
  const items = mapHeaderData(headerData, sliceType)?.default;
  const mapped =
    items?.map((item) => {
      const parsedData = parseLink(item.data.textLink);

      return {
        ...parsedData,
        isGuestOnly: item.data.visibility === 'guest_only',
        isAuthOnly: item.data.visibility === 'auth_only',
        levels: mapMenuItemLevels(item.data.slices),
        disabledForUserRole: item.data?.disabledForUserRole,
      };
    }) ?? [];
  return mapped;
};

const mapMenuItemLevels = (
  slices: NavigationMenuItemData['slices']
): MenuLevel[] => {
  const mapped = slices?.map((slice) => {
    const secondLevelParsed = {
      ...parseLink(slice.primary.textLink),
      isGuestOnly: slice.primary.visibility === 'guest_only',
      isAuthOnly: slice.primary.visibility === 'auth_only',
    };
    const thirdLevelParsed = slice.items?.map((subcategory) => ({
      ...parseLink(subcategory.textLink),
      isGuestOnly: subcategory.visibility === 'guest_only',
      isAuthOnly: subcategory.visibility === 'auth_only',
    }));

    return {
      parent: secondLevelParsed,
      children: thirdLevelParsed,
    };
  });

  return mapped;
};

export const isProtectedLevel = (
  isAuthenticated: boolean,
  isAuthOnly: boolean,
  isGuestOnly: boolean
): boolean => {
  const isNotAuthorized =
    (!isAuthenticated && isAuthOnly && !isGuestOnly) ||
    (isAuthenticated && isGuestOnly && !isAuthOnly);

  return isNotAuthorized;
};
