import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import { COOKIE_STORAGE } from '@lib/storage/constants';

import { A2HSText } from './A2HSText';
import { useA2HSPrompt } from './useA2HSPrompt';

export const useA2HS = (): void => {
  const [hasDismissedA2HS, dismissA2HS] = useState<boolean>(true);
  const [promptEvent, promptToInstall] = useA2HSPrompt();

  const handleA2HSToastClose = useCallback(() => {
    dismissA2HS(true);
    Cookies.set(COOKIE_STORAGE.A2HS_DISMISSED, '1');
  }, []);

  useEffect(() => {
    const dismissed = !!Cookies.get(COOKIE_STORAGE.A2HS_DISMISSED);

    if (!dismissed) {
      dismissA2HS(false);
    }
  }, []);

  useEffect(() => {
    if (!hasDismissedA2HS && promptEvent) {
      toast.info(<A2HSText />, {
        autoClose: false,
        closeOnClick: false,
        onClick: promptToInstall,
        onClose: handleA2HSToastClose,
      });
    }
  }, [hasDismissedA2HS, handleA2HSToastClose, promptEvent, promptToInstall]);
};
