import { FC, useCallback, useEffect, useState } from 'react';
import { useIsFetching } from '@tanstack/react-query';
import { Router } from 'next/router';

import { useAuth } from '@lib/auth/AuthContext';
import { SxStyles } from '@lib/theme/sxTheme';
import { LinearProgress } from '@mui/material';

export const ProgressBar: FC = () => {
  const [showProgress, toggleProgress] = useState<boolean>(false);
  const isFetching = useIsFetching();
  const { isLoggingOut } = useAuth();
  const memoShowProgress = useCallback(() => {
    toggleProgress(true);
  }, []);
  const memoHideProgress = useCallback(() => {
    toggleProgress(false);
  }, []);

  useEffect(() => {
    Router.events.on('routeChangeStart', memoShowProgress);
    Router.events.on('routeChangeComplete', memoHideProgress);
    Router.events.on('routeChangeError', memoHideProgress);

    return () => {
      Router.events.off('routeChangeStart', memoShowProgress);
      Router.events.off('routeChangeComplete', memoHideProgress);
      Router.events.off('routeChangeError', memoHideProgress);
    };
  }, [memoShowProgress, memoHideProgress]);

  if (isFetching === 0 && !showProgress && !isLoggingOut) {
    return null;
  }

  return (
    <LinearProgress
      sx={styles.getValue('progressIndicator')}
      color="primary"
      variant="indeterminate"
    />
  );
};

const styles = new SxStyles({
  progressIndicator: {
    position: 'absolute',
    bottom: -4,
    left: 0,
    width: '100%',
  },
});
