import { FC, ReactNode } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import { scrollOnActive } from '@lib/scroll/elementScroll';
import { SxStyles } from '@lib/theme/sxTheme';
import {
  Link as MaterialLink,
  LinkProps as MaterialLinkProps,
  Typography,
  TypographyProps,
} from '@mui/material';

export interface TextLinkProps extends Omit<MaterialLinkProps, 'href'> {
  href: NextLinkProps['href'];
  children?: ReactNode;
  typographyProps?: TypographyProps;
}

export const TextLink: FC<TextLinkProps> = (props) => {
  const {
    href,
    children,
    typographyProps = {},
    color = 'primary',
    underline = 'hover',
    rel = 'nofollow noopener noreferrer',
    ...rest
  } = props;
  const { sx, ...restTypographyProps } = typographyProps;

  return (
    <NextLink href={href} scroll={false} passHref legacyBehavior>
      <MaterialLink
        color={color}
        onClick={scrollToElement}
        underline={underline}
        rel={rel}
        {...rest}
      >
        <Typography
          sx={styles.merge<false>('root', sx)}
          component="span"
          {...restTypographyProps}
        >
          {children}
        </Typography>
      </MaterialLink>
    </NextLink>
  );

  function scrollToElement(): void {
    scrollOnActive(href, window.location);
  }
};

const styles = new SxStyles({
  root: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
});
