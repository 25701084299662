import { FC } from 'react';
import dynamic from 'next/dynamic';

import { SxStyles } from '@lib/theme/sxTheme';
import { Box } from '@mui/material';

import { ProgressBar } from './ProgressBar';

const DynamicHeaderBarNoSSR = dynamic(() => import('./header-bar/HeaderBar'), {
  ssr: false,
});

export const Header: FC = () => {
  return (
    <Box sx={styles.getValue('root')}>
      <DynamicHeaderBarNoSSR />
      <ProgressBar />
    </Box>
  );
};

const styles = new SxStyles({
  root: {
    position: 'relative',
    zIndex: 'appBar',
    flexGrow: 1,
  },
});
