import { FC, ReactNode } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import { LoadingButtonProps } from '@mui/lab';
import {
  Box,
  BoxProps,
  ButtonProps,
  LinkProps as MaterialLinkProps,
} from '@mui/material';

import { Button } from './Button';

export interface LinkButtonProps
  extends Omit<LoadingButtonProps, 'href' | 'onClick'> {
  href: NextLinkProps['href'];
  children: ReactNode;
  linkProps?: NextLinkProps;
  target?: MaterialLinkProps['target'];
  rel?: MaterialLinkProps['rel'];
  textProps?: BoxProps;
  size?: ButtonProps['size'];
}

export const LinkButton: FC<LinkButtonProps> = ({
  href,
  color,
  linkProps,
  variant = 'text',
  children,
  textProps = {},
  size,
  ...restProps
}) => {
  const { color: textColor, ...restTextProps } = textProps;
  restProps.rel = 'nofollow noopener noreferrer';

  return (
    <NextLink href={href} {...linkProps} passHref legacyBehavior>
      <Button
        color={variant === 'text' && !color ? 'dark' : color}
        type="button"
        variant={variant}
        size={size}
        {...restProps}
      >
        <Box
          component="span"
          display="block"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          color={textColor ?? 'inherit'}
          {...restTextProps}
        >
          {children}
        </Box>
      </Button>
    </NextLink>
  );
};
