import { FC, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { SxStyles } from '@lib/theme/sxTheme';
import { Box } from '@mui/material';
import { ContentFallback } from '@ui/error-boundaries/ContentFallback';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';

interface Props {
  children: ReactNode;
  showContentLoader?: boolean;
}

export const Body: FC<Props> = (props) => {
  const { children, showContentLoader } = props;

  return (
    <Box sx={styles.getValue('container')}>
      <ErrorBoundary FallbackComponent={ContentFallback}>
        {showContentLoader ? <CenteredLoader /> : <Box>{children}</Box>}
      </ErrorBoundary>
    </Box>
  );
};

const styles = new SxStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
});
